<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>

                <b-form @submit.prevent="search()" >
                  <div class="filter-transaction">
                    <div class="row mb-6">
                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <input
                            type="text"
                            placeholder="Nhập số điện thoại..."
                            class="form-control datatable-input"
                            v-model="paramFilter.phone"
                        />
                      </div>
                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <select
                            class="form-control datatable-input"
                            data-col-index="7"
                            v-model="paramFilter.product_id"
                        >
                          <option value="">--Chọn campaign--</option>
                          <option v-for="(prod, index) in products" :key="index" :value="prod.id">{{ prod.name }}</option>
                        </select>
                      </div>
                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <select
                            class="form-control datatable-input"
                            data-col-index="7"
                            v-model="paramFilter.status"
                        >
                          <option value="">--Trạng thái--</option>
                          <option value="CREATED">CREATED</option>
                          <option value="SUCCESS">SUCCESS</option>
                          <option value="FAIL">FAIL</option>
                        </select>
                      </div>
                    </div>

                    <div class="row mb-6">
                      <b-form-group
                          id="input-group-4"
                          label="Từ ngày:"
                          label-for="dp1"
                          class="col-lg-4"
                      >
                        <date-picker
                            id="dp1"
                            name="dp1"
                            format="YYYY-MM-DD "
                            value-type="format"
                            v-model="paramFilter.date_start"
                            type="date"
                            placeholder="Chọn thời gian"
                        ></date-picker>
                      </b-form-group>
                      <b-form-group
                          id="input-group-4"
                          label="Đến ngày:"
                          label-for="dp1"
                          class="col-lg-4"
                      >
                        <date-picker
                            id="dp1"
                            name="dp1"
                            format="YYYY-MM-DD "
                            value-type="format"
                            v-model="paramFilter.date_end"
                            type="date"
                            placeholder="Chọn thời gian"
                        ></date-picker>
                      </b-form-group>
                      <div class="col-lg-2 mb-lg-0 mb-6">
                        <label>.</label><br />
                        <button
                            class="btn btn-primary btn-primary--icon"
                        >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                        </button>
                      </div>
                    </div>

                  </div>
                </b-form>
              </template>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(stt)="data">
                  <div >
                    {{ data.index + 1}}
                  </div>
                </template>
                <template #cell(user_info)="data">
                  <div >
                    {{data.item.user_id}} - {{data.item.user.phone}}
                  </div>
                </template>
                <template #cell(campaign)="data">
                  <div >
                    {{data.item.product.name}}
                  </div>
                </template>
                <template #cell(status)="data">
                  <div class="label label-inline label-light-success" v-if="data.item.status == 'SUCCESS'">
                    {{ data.item.status }}
                  </div>
                  <div class="label label-inline label-light-danger" v-else-if="data.item.status == 'FAIL'">
                    {{ data.item.status }}
                  </div>
                  <div class="label label-inline label-light-warning" v-else>
                    {{ data.item.status }}
                  </div>
                </template>

              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const BackendRepository = RepositoryFactory.get("Backend");

export default {
  name: "CharityUser.vue",
  mixins: [Common],
  components:{
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "user_info",
          label: "Thông tin user",
        },
        {
          key: "campaign",
          label: "Chương trình",
        },
        {
          key: "amount",
          label: "Số tiền",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "created_at",
          label: "Ngày tham gia",
        },

      ],
      items: [],
      currentPage: 1,
      paramFilter:{
        phone:'',
        product_id:'',
        status:'',
        date_start:'',
        date_end:'',
      },
      arrayFilter:[],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      products: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách user quyên góp", route: "/campaign-charities/users" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await BackendRepository.charityListUsers(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.items = body.data;
          this.arrayFilter = body.array_filter;
          this.paginate.total = body.meta.total;
          this.paginate.totalPage = body.meta.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getProducts() {
      let response = await BackendRepository.listCampaignCharities({
        is_get_all: 1
      });
      this.products = response.data.data;
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getProducts();
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
